import { CSSProperties, ReactNode } from 'react'
import classNames from 'classnames'
import { Tabs, TabsProps } from 'antd'

import styles from './styles.module.less'

/** 页面级页签组件 */
export const PageTabs = ({
  className,
  style,
  tabBarStyle,
  activeKey,
  onChange,
  items,
}: {
  className?: string
  style?: CSSProperties
  tabBarStyle?: TabsProps['tabBarStyle']
  activeKey?: TabsProps['activeKey']
  onChange?: TabsProps['onChange']
  items: { key: string; label: ReactNode }[]
}) => {
  return (
    <Tabs
      className={classNames(styles.pageTabs, className)}
      style={style}
      size="small"
      tabBarGutter={40}
      tabBarStyle={{ marginBottom: 0, paddingLeft: 8, ...tabBarStyle }}
      activeKey={activeKey}
      animated={false}
      onChange={onChange}
      items={items}
    />
  )
}
