import { useEffect, useRef } from 'react'

/** 主要用于中止请求 */
export const useControllerRef = () => {
  const controllerRef = useRef<AbortController>(new AbortController())

  useEffect(() => () => controllerRef.current.abort(), [])

  return controllerRef
}
