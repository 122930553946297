import { Translation } from 'react-i18next'

/** 默认 pageSize */
export const DEFAULT_PAGE_SIZE = 20

/** 默认 pageSizeOptions */
export const PAGE_SIZE_OPTIONS = [10, 20, 40]

/**
 * 生成默认的分页配置
 */
export const getDefaultPaginationProps = (total: number) => {
  return {
    size: 'small' as const,
    total,
    showTotal: (total: number) => <Translation>{t => t('2-common.gong-total-tiao', { total })}</Translation>,
    pageSizeOptions: PAGE_SIZE_OPTIONS,
    hideOnSinglePage: true as const,
  }
}
